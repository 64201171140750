



































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { AdminPanelClient, AdminPanelClientFullInfo } from "@/types";
import { getClientsList, getClientFullInfo } from "@/api/admin-panel";
import moment from "moment";
import _ from "lodash";
import { Watch } from "vue-property-decorator";
import axios from "axios";

@Component
export default class AdminClientsView extends Vue {
  clients: AdminPanelClient[] = []
  loading = false
  tableHeaders = [
    { text: '#', value: 'id', width: 40 },
    { text: 'Имя', value: 'name' },
    { text: 'Логин', value: 'login', align: 'right' },
    { text: '# лицензии', value: 'licenseId', align: 'right' },
    { text: 'Цена', value: 'price', align: 'right' },
    { text: 'ТСД', value: 'tsdAmount', align: 'right' },
    { text: '1С', value: 'workspacesAmount', align: 'right' },
    { text: 'Статус лицензии', value: 'status', align: 'right' },
    { text: 'Окончание', value: 'expire', align: 'right' },
    { text: 'Тип лицензии', value: 'type', align: 'right' },
  ]

  search: string | null = ''

  debouncedGetClients = _.debounce(this.getClients, 300)

  @Watch('search')
  onSearchChanges(): void {
    if (this.debouncedGetClients) {
      this.debouncedGetClients()
    }
  }

  async getClients (): Promise<void> {
    this.loading = true
    this.clients = await getClientsList(this.search)
    this.loading = false
  }

  getFormattedDate (rawDate: string): string {
    return moment(rawDate, 'DD.MM.YYYY').format('MMMM Do YYYY г.')
  }

  clientInfo: AdminPanelClientFullInfo | null = null
  loadingInfo = false

  async getClientInfo (id: number): Promise<void> {
    this.loadingInfo = true
    this.clientInfo = await getClientFullInfo(id)
    this.showClientInfoDialog = true
    this.loadingInfo = false
  }

  showClientInfoDialog = false

  showConfirmDeviceDelete = false

  deviceToDelete = {
    serial: '',
    type: ''
  }

  openConfirmDeleteDialog(serial: string, type: string): void {
    this.deviceToDelete.serial = serial
    this.deviceToDelete.type = type
    this.showConfirmDeviceDelete = true
  }

  async deleteDevice(): Promise<void> {
    await axios.delete('/deleteTSD/', {
      params: {
        serial: this.deviceToDelete.serial
      }
    })
    if (this.clientInfo) {
      await this.getClientInfo(this.clientInfo.id)
    }
    this.showConfirmDeviceDelete = false
    await this.getClients()
  }

  showSuccessSnackbar = false

  async changeLicense(): Promise<void> {
    if (this.clientInfo && this.clientInfo.license) {
      await axios.post(`/api/admin/changeLicense/${ this.clientInfo.license.id}/`, {
        tsds: this.clientInfo.license.tsdAmount,
        workspaces: this.clientInfo.license.workspacesAmount,
        price: this.clientInfo.license.price,
      })
      await this.getClients()
      this.showSuccessSnackbar = true
      // this.showClientInfoDialog = false
    }
  }

  created (): void {
    moment.locale('ru')
    this.getClients()
  }
}
