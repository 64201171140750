import axios from "axios";
import { AdminPanelClient, AdminPanelClientFullInfo } from "@/types";

export async function getClientsList (query: string | null): Promise<AdminPanelClient[]> {
  const {data} = await axios.get('api/admin/clients/', {
    params: {
      query: query
    }
  })
  return data
}

export async function getClientFullInfo (id: number): Promise<AdminPanelClientFullInfo> {
  const {data} = await axios.get(`api/admin/getProfile/${id}/`)
  return data
}